import React from 'react'
import { Container, Header, Grid, Button, Image } from 'semantic-ui-react'
import Link from 'next/link'

import Text from 'lib/components/Text'
import ExternalLink from 'lib/components/ExternalLink'

const HowItWorks = ({}) => (
  <Container>
    <Header textAlign="center" as="h2">
      How It Works
    </Header>

    <Text>
      Our product watches Recall and Safety Alerts from{' '}
      <ExternalLink href="http://www.hc-sc.gc.ca/index-eng.php">
        Health Canada
      </ExternalLink>
      ,{' '}
      <ExternalLink href="http://www.tc.gc.ca/eng/tc-main.htm">
        Transport Canada
      </ExternalLink>{' '}
      and the{' '}
      <ExternalLink href="http://www.inspection.gc.ca/english/toce.shtml">
        Canadian Food Inspection Agency
      </ExternalLink>{' '}
      24/7 and sends you notifications for the products you care about as soon
      as they are published.
    </Text>
    <Text>
      The alerts provide detailed guidance on what to do and how to be
      protected.
    </Text>

    <Grid
      stackable
      columns={3}
      divided
      centered
      css={{
        fontSize: '1rem',
        margin: '1rem 0 !important',
      }}
    >
      <Grid.Row verticalAlign="middle" textAlign="center">
        <Grid.Column width={4} textAlign="center">
          <Image
            src="/static/home/signup.svg"
            css={{ width: 'auto', height: '4rem' }}
            centered
          />
          <Header>Sign Up</Header>
          <p>Subscribe to start being protected.</p>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            src="/static/home/customize.svg"
            css={{ width: 'auto', height: '4rem' }}
            centered
          />
          <Header>Customize preferences</Header>
          <p>Adjust your settings to indicate alerts you care about.</p>
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Image
            src="/static/home/getnotified.svg"
            css={{ width: 'auto', height: '4rem' }}
            centered
          />
          <Header>Get Notified</Header>
          <p>Rest easy knowing you'll get notified instantly.</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <div css={{ textAlign: 'center' }}>
      <Link href="/signup" passHref>
        <Button as="a" primary size="large" css={{ margin: 'auto !important' }}>
          Get Started
        </Button>
      </Link>
    </div>
  </Container>
)

export default HowItWorks
