import React from 'react'
import {
  Button,
  Container,
  Divider as SDivider,
  Grid,
  Header,
  Icon,
  List,
  Image,
  Card,
} from 'semantic-ui-react'
import Link from 'next/link'

import SEO from 'lib/components/SEO'
import Text from 'lib/components/Text'
import {
  EmailAndText,
  Filters,
  Search,
  Vehicles,
} from 'lib/components/Features'

import Underlined from './Underlined'
import Carousel from './Carousel'
import HowItWorks from './HowItWorks'

// #2185d0

const Divider = props => (
  <SDivider css={{ margin: '2rem 0 !important' }} {...props} />
)

const Home = () => (
  <>
    <SEO
      title="Get notified of Canadian Product and Vehicle Recalls and Safety Alerts | Recall Monitor"
      canonical="https://recallmonitor.ca"
      description="Get alerted instantly when a food, product, vehicle or medicine you own is recalled."
    />
    <div
      // @ts-ignore
      css={{
        background: `black url(/static/home/hero.webp)`,
        backgroundSize: 'cover',
        backgroundPosition: '50%',
        marginBottom: '2rem',
      }}
    >
      <div
        // @ts-ignore
        css={{
          background: 'rgba(33, 133, 208, 0.8)',
          padding: '6rem 0 4rem 0',
          '.header': {
            color: 'white !important',
          },
        }}
      >
        <Container text textAlign="center">
          <Header as="h1">
            Protect <Underlined>yourself</Underlined>,{' '}
            <Underlined>your family</Underlined> and{' '}
            <Underlined>your business</Underlined> from product recalls
          </Header>
          <Header as="h2">
            Recall Monitor alerts you when products you own are recalled.
          </Header>
          <Link href="/signup" passHref>
            <Button
              as="a"
              color="green"
              size="huge"
              css={{ marginTop: '2rem !important' }}
            >
              Get Alerted Now
            </Button>
          </Link>
        </Container>
      </div>
    </div>

    <Carousel />

    <EmailAndText>
      <Link href="/signup" passHref>
        <Button as="a" color="green">
          Get Protected Now
        </Button>
      </Link>
    </EmailAndText>

    <Divider />

    <HowItWorks />

    <Divider />

    <Container>
      <Header
        as="h2"
        content="What our users say about us"
        textAlign="center"
      />
      <Card.Group
        items={[
          {
            header: 'Margot A.',
            description:
              'Great and works as expected. I recommend this to anyone that wants to stay on top of recalls and keep their family safe.',
            meta: 'Vancouver, BC',
          },
          {
            header: 'Ray N.',
            description:
              'This is an amazing resource to have!! Definitely recommend it to all users!',
            meta: 'Grande Prairie, AB',
          },
          {
            header: 'Javi K.',
            description:
              'As a parent of a child with a nut allergy, I know I can keep my family safe and get alerted right away. Great product!',
            meta: 'Laval, QC',
          },
          {
            header: 'Brenda C.',
            description:
              'Great program to keep up to date on all recalls that may personally affect you!!',
            meta: 'Kamloops, BC',
          },
        ]}
        css={{
          justifyContent: 'center !important',
          '.card': {
            width: '250px',
          },
        }}
      />
    </Container>

    <Divider />

    <Filters />

    <Divider />

    <Container>
      <Grid stackable columns={2}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={10}>
            <Header as="h2" content="Get notified when it matters" />
            <Text>
              Often consumers learn of recalls days or weeks after one is issued
              by stumbling across a news article or post on social media.
            </Text>
            <Text>
              Recall Monitor makes life easier for Canadian consumers by
              notifying them of recalls that apply to them.
            </Text>

            <Link href="/signup" passHref>
              <Button as="a" primary>
                Sign Up <Icon name="arrow right" />
              </Button>
            </Link>
          </Grid.Column>
          <Grid.Column width={6}>
            <Image
              src="/static/home/mobile-life.svg"
              css={{ width: '80%', height: 'auto', margin: '0 auto' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

    <Divider />

    <Vehicles inverted />

    <Divider />

    <Container>
      <Grid stackable columns={2}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={6}>
            <Image
              src="/static/home/team-spirit.svg"
              css={{ width: '80%', height: 'auto', margin: '0 auto' }}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Header as="h2" content="For all consumers" />
            <Text>
              Knowing about recalls is useful for many people, including:
            </Text>
            <List
              css={{ fontSize: '1.14em !important' }}
              bulleted
              items={[
                {
                  key: 'allergy',
                  header: 'People with allergies',
                  content: 'know about undeclared allergens in foods',
                },
                {
                  key: 'business',
                  header: 'Business owners',
                  content: 'avoid liability and serve customers better',
                },
                {
                  key: 'medical',
                  header: 'Medical professionals',
                  content:
                    'learn about medical devices that can inhibit treatment',
                },
                {
                  key: 'consumers',
                  header: 'Consumers',
                  content: 'be safe and avoid injuries from faulty products',
                },
                {
                  key: 'families',
                  header: 'Families',
                  content:
                    'avoid microbiological threats like E.coli, listeria and salmonella',
                },
              ]}
            />

            <Link href="/signup" passHref>
              <Button as="a" primary>
                Get Started <Icon name="arrow right" />
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>

    <Divider />

    <Search />

    <Divider />

    <Container>
      <Grid stackable columns={2}>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={10}>
            <Header as="h2" content="Let Recall Monitor help" />
            <Text>
              We know you are busy. In an effort to reduce your time fishing
              through alerts for ones that apply to you, you may choose to be
              sent only notifications for specific product types. You may even
              choose to be only sent products containing a certain keyword (for
              example, “peanuts” or “dairy”). Save yourself from{' '}
              <a
                href="https://www.ctvnews.ca/lifestyle/recall-fatigue-may-prompt-canadians-to-avoid-serving-some-foods-over-holidays-1.4221500"
                target="_blank"
                rel="noreferrer noopener"
              >
                alert fatigue
              </a>{' '}
              and allow Recall Monitor to pick out relevant recall alerts for
              you and your family.
            </Text>
            <Text>
              Don't leave your safety and the safety of the ones you care about
              to chance. Let Recall Monitor Help
            </Text>

            <Link href="/signup" passHref>
              <Button as="a" primary>
                Start Protecting Yourself <Icon name="arrow right" />
              </Button>
            </Link>
          </Grid.Column>
          <Grid.Column width={6}>
            <Image
              src="/static/home/businessman.svg"
              css={{ width: '80%', height: 'auto', margin: '0 auto' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  </>
)

export default Home
