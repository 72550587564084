import { WithStore } from 'pure-react-carousel'
import { forwardRef } from 'react'
import { Button } from 'semantic-ui-react'

const BackFwd = WithStore(
  // @ts-ignore
  forwardRef(
    // @ts-ignore
    ({ currentSlide, totalSlides, visibleSlides, carouselStore }, ref) => {
      return (
        <>
          <Button
            icon="chevron left"
            onClick={() => {
              carouselStore.setStoreState({
                currentSlide:
                  currentSlide === 0
                    ? totalSlides - visibleSlides
                    : currentSlide - 1,
              })
            }}
            css={{
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translate(-100%, -50%)',
            }}
          />
          <Button
            icon="chevron right"
            onClick={() => {
              carouselStore.setStoreState({
                currentSlide:
                  currentSlide === totalSlides - visibleSlides
                    ? 0
                    : currentSlide + 1,
              })
            }}
            css={{
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translate(100%, -50%)',
            }}
          />
        </>
      )
    },
  ),

  s => ({
    currentSlide: s.currentSlide,
    totalSlides: s.totalSlides,
    visibleSlides: s.visibleSlides,
  }),
)

export default BackFwd
