import { Segment, Container, Header, Button } from 'semantic-ui-react'
import Link from 'next/link'

import MediaBreakpointLG from 'lib/components/MediaBreakpointLG'

import Content from './Content'

const Carousel = () => {
  return (
    <>
      <Container text textAlign="center" css={{ margin: '2rem 0' }}>
        <Header as="h2">
          In 2020, 1,350+ products were recalled. Are any of these in your home
          or business?
        </Header>
        <Segment basic css={{ userSelect: 'none' }}>
          <Container>
            <MediaBreakpointLG
              lessThan={<Content visibleSlides={1} />}
              greaterOrEqual={<Content visibleSlides={3} />}
            />
          </Container>
        </Segment>

        <Link href="/signup" passHref>
          <Button as="a" primary size="large">
            Recall Monitor can alert you
          </Button>
        </Link>
      </Container>
    </>
  )
}

export default Carousel
