import { CarouselProvider, Slider } from 'pure-react-carousel'
import BackFwd from './BackFwd'
import CustomCardSlide from './CustomCardSlide'

import 'pure-react-carousel/dist/react-carousel.es.css'

const Content = ({ visibleSlides }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={1.25}
      totalSlides={8}
      visibleSlides={visibleSlides}
      touchEnabled={false}
      dragEnabled={false}
      // @ts-ignore
      css={{ position: 'relative' }}
    >
      <BackFwd />
      <Slider>
        <CustomCardSlide
          header="Counterfeit Daily Shield Hand Sanitizer"
          image="https://assets.recallmonitor.ca/74137/dailyshield-front_s_fs_0_20201018-144437_12_en.jpg"
          meta="Advisory"
          link="recall/74137"
        />
        <CustomCardSlide
          header="Ruffles Hot Wings Potato Chips"
          image="https://assets.recallmonitor.ca/1588723326478/20200505a_1588723929134_eng.jpg"
          meta="Undeclared Mustard"
          link="/recall/72985"
        />
        <CustomCardSlide
          header="Compliments Smoked Beef Pastrami"
          image="https://assets.recallmonitor.ca/74385/20201122a_1606091229758_eng.jpg"
          meta="Listeria"
          link="/recall/74385"
        />
        <CustomCardSlide
          header="Method Gel Hand Wash"
          image="https://assets.recallmonitor.ca/74345/ra-74345-01_s_fs_3_20201116-172947_12_en.jpg"
          meta="Contamination"
          link="/recall/74345"
        />
        <CustomCardSlide
          header="Eco Feeding Set"
          image="https://assets.recallmonitor.ca/74373/ra-74373-01_s_fs_3_20201119-192624_17_en.jpg"
          meta="Choking Hazard"
          link="recall/74373"
        />
        <CustomCardSlide
          header="ECHO / Shindaiwa Backpack Blowers"
          image="https://assets.recallmonitor.ca/72967/ra-72967-01_s_fs_3_20200504-190522_18_en.jpg"
          meta="Laceration & Impact Hazard"
          link="/recall/72967"
        />
        <CustomCardSlide
          image="https://assets.recallmonitor.ca/1586996841974/20200405d_1586114758000_eng.jpg"
          header="Le Mont-Jacob"
          meta="Listeria"
          link="/recall/72771"
        />
        <CustomCardSlide
          header="Reverse Fleece Hooded Sweatshirt"
          image="https://assets.recallmonitor.ca/72969/ra-72969-01_s_fs_3_20200505-163305_15_en.jpg"
          meta="Flammability Hazard"
          link="/recall/72969"
        />
      </Slider>
    </CarouselProvider>
  )
}

export default Content
