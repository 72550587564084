import { Card } from 'semantic-ui-react'
import Link from 'next/link'
import { Slide } from 'pure-react-carousel'

const CustomCardSlide = ({
  index,
  link,
  ...cardProps
}: {
  index?: any
  link: string
  [key: string]: any
}) => (
  <Slide index={index}>
    <div style={{ padding: '1rem', height: '100%' }}>
      <Link href={link} passHref>
        <Card
          as="a"
          fluid
          {...cardProps}
          css={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            '.image': {
              flex: '1 0 auto !important',
              display: 'flex !important',
              alignItems: 'center !important',
              justifyContent: 'center !important',
              height: '60%',
              img: {
                width: '100% !important',
                height: '100% !important',
                objectFit: 'contain',
              },
            },
            '.content': {
              flex: '0 1 auto !important',
            },
          }}
        />
      </Link>
    </div>
  </Slide>
)

export default CustomCardSlide
