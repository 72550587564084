import React from 'react'

const Underlined = ({ children }) => (
  <span
    css={{
      textDecoration: 'underline',
      textDecorationColor: '#21ba45',
    }}
  >
    {children}
  </span>
)

export default Underlined
