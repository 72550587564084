import React from 'react'
import { Header, Grid, Image, Segment } from 'semantic-ui-react'

const Feature = ({
  img,
  children,
  reversed = false,
  inverted = true,
  style = {},
}) => {
  const randomId = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
  const image = (
    <Grid.Column
      width={8}
      id={randomId}
      css={{
        '@media only screen and (max-width: 767px)': {
          [`&#${randomId}`]: {
            padding: '0 !important',
          },
        },
      }}
    >
      <Image src={img} width="100%" height="auto" />
    </Grid.Column>
  )

  const text = (
    <Grid.Column width={8} style={{ display: 'flex' }}>
      <div style={{ [reversed ? 'marginRight' : 'marginLeft']: 'auto' }}>
        {children}
      </div>
    </Grid.Column>
  )

  return (
    <Segment
      basic
      inverted={!inverted}
      style={{
        background: inverted ? 'white' : 'rgba(33, 133, 208, 1)',
        margin: 0,
        ...style,
      }}
    >
      <Grid stackable columns={2} container>
        <Grid.Row verticalAlign="middle" style={{ paddingBottom: 0 }}>
          {reversed ? image : text}
          {reversed ? text : image}
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export const EmailAndText = ({ children = null, inverted = false }) => (
  <Feature img="/static/home/alert.webp" inverted={inverted}>
    <Header inverted={!inverted} as="h2">
      Instant email or text alerts.
    </Header>
    <p>
      Never miss an important recall, ever. Recall Monitor is the fastest
      solution to get information on Canadian recalls, before they become
      dangerous.
    </p>
    {children}
  </Feature>
)

export const Filters = ({ inverted = false }) => (
  <Feature img="/static/get/filters.webp" inverted={inverted}>
    <Header inverted={!inverted} as="h2">
      Tailored to your needs.
    </Header>
    <p>
      Receive alerts for every recall, or track recalls by Alert Type, Category
      or by defining your own keywords. Unlimited flexibility to help you
      receive the most important alerts in the most convenient way.
    </p>
  </Feature>
)

export const Vehicles = ({ inverted = false }) => (
  <Feature img="/static/get/vehicle.webp" inverted={inverted}>
    <Header inverted={!inverted} as="h2">
      Track recalls on your vehicle
    </Header>
    <p>
      Track past and future recalls on your vehicle and get notified as soon as
      a recall affecting your own vehicle comes in.
    </p>
  </Feature>
)

export const Search = ({ inverted = false }) => (
  <Feature img="/static/get/search.webp" inverted={inverted}>
    <Header as="h2" inverted={!inverted}>
      Search recalls by keyword and barcode.
    </Header>
    <p>
      Search our huge catalog of more than 29,000 recalls by keywords, category
      or enter barcodes for products you own to see if they were recalled.
    </p>
  </Feature>
)

export default function Features() {
  return (
    <>
      <EmailAndText />
      <Filters inverted />
      <Vehicles />
      <Search inverted />
    </>
  )
}
